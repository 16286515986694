
import { Vue, Component } from 'vue-property-decorator';
import { AxiosError } from 'axios';
import { ErrorResponseModel } from '@/model/StaffManager';
import UserRegistApi from '@/module/api/UserRegistApi';
import Email from '@/model/user/Email';

@Component({ components: { } })
export default class PasswordForgot extends Vue {
  /** data */
  email: Email = new Email();
  resendFlg: boolean = false;

  /**
   * 送信ボタン押下
   */
  send():void {
    UserRegistApi.reissue(this.email)
      .then(() => {
        this.resendFlg = true;
      }).catch((error: AxiosError<ErrorResponseModel>) => {
        if (error.response?.data.code === 'NoExistsRecord') {
          this.$root.$emit('event-show-snackbar-infinity', '登録されていないメールアドレスです。');
          return;
        }
        this.$root.$emit('event-show-snackbar-infinity', '予期しないエラーが発生しました');
      });
  }

  /**
   * 戻るボタン押下
   */
  back(): void {
    this.$router.push('/login.html');
  }
}
